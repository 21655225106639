// src/components/VerifyOTP.js
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AWS from "aws-sdk";
import awsConfig from "./awsConfig";

AWS.config.update({ region: awsConfig.region });
const cognito = new AWS.CognitoIdentityServiceProvider();

const VerifyOTP = () => {
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;

  const verifyOtp = async () => {
    try {
      await cognito
        .confirmSignUp({ ClientId: awsConfig.clientId, Username: email, ConfirmationCode: otp })
        .promise();
      alert("Account verified! Please log in.");
      navigate("/login");
    } catch (error) {
      console.error("OTP verification error:", error);
      alert("OTP verification failed.");
    }
  };

  return (
    <div>
      <h2>Verify OTP</h2>
      <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter OTP" />
      <button onClick={verifyOtp}>Verify OTP</button>
    </div>
  );
};

export default VerifyOTP;
