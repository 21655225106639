import React from 'react';

const ViewStudentPhoto = () => {
  // Hardcoded pre-signed URL and file name
  const preSignedUrl = 'https://mpldev-user-files.s3.ap-south-1.amazonaws.com/organization_uploads/20d635df-40e3-40a5-943f-a8a6b70d0ad4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIATP7CM73MBJUUC6HJ%2F20241205%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20241205T192833Z&X-Amz-Expires=300&X-Amz-Signature=8051f7d1ae2c4bcd610d4a6c214a6ad32422cb1ddf40527f9f2fd9d44b350c26&X-Amz-SignedHeaders=host&x-id=GetObject'; // Replace with actual URL
  const fileName = 'organziation_uploads/20d635df-40e3-40a5-943f-a8a6b70d0ad4.jpg';
  return (
    <div style={{ padding: '20px', maxWidth: '500px', margin: '0 auto', textAlign: 'center' }}>
      <h1>View Student Photo</h1>
      <p><strong>File Name:</strong> {fileName}</p>
      <div style={{ marginTop: '20px' }}>
        <h2>Student Photo:</h2>
        <img
          src={preSignedUrl}
          alt="Student"
          style={{ maxWidth: '100%', height: 'auto', border: '1px solid #ccc', padding: '10px' }}
        />
      </div>
    </div>
  );
};

export default ViewStudentPhoto;
