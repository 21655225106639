import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import awsConfig from "./awsConfig";

AWS.config.update({ region: awsConfig.region });
const cognito = new AWS.CognitoIdentityServiceProvider();

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "+1", // Default country code
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const registerUser = async () => {
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    // Combine country code and phone number
    const phoneNumber = `${formData.countryCode}${formData.phone}`;

    const params = {
      ClientId: awsConfig.clientId,
      Username: formData.email,
      Password: formData.password,
      UserAttributes: [
        { Name: "name", Value: formData.name },
        { Name: "email", Value: formData.email },
        { Name: "phone_number", Value: phoneNumber }, // Combine country code and phone
      ],
    };

    try {
      await cognito.signUp(params).promise();
      alert("Registration successful! Please check your email for the OTP.");

      // Navigate to Verify OTP page with the email
      navigate("/verify-otp", { state: { email: formData.email } });
    } catch (error) {
      if (error.code === "UsernameExistsException") {
        alert("This email is already registered. Please log in.");
      } else {
        console.error("Error during sign-up:", error);
        alert("Registration failed. Please try again.");
      }
    }
  };

  return (
    <div>
      <h2>Register</h2>
      <input
        type="text"
        name="name"
        placeholder="Full Name"
        value={formData.name}
        onChange={handleChange}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
      />
      <input
        type="text"
        name="countryCode"
        placeholder="Country Code"
        value={formData.countryCode}
        onChange={handleChange}
      />
      <input
        type="text"
        name="phone"
        placeholder="Phone Number"
        value={formData.phone}
        onChange={handleChange}
      />
      <input
        type="password"
        name="password"
        placeholder="Password"
        value={formData.password}
        onChange={handleChange}
      />
      <input
        type="password"
        name="confirmPassword"
        placeholder="Confirm Password"
        value={formData.confirmPassword}
        onChange={handleChange}
      />
      <button onClick={registerUser}>Register</button>
    </div>
  );
};

export default Register;
