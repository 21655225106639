// src/Home.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [jwtToken, setJwtToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("idToken"); // Check if there's a token stored
    setJwtToken(token);
  }, []);

  const logout = () => {
    // Clear the JWT token from localStorage
    localStorage.removeItem("idToken");
    alert("You have been logged out.");
    navigate("/login"); // Redirect to login page after logout
  };

  return (
    <div>
      <h2>Welcome to the Home Page</h2>
      {jwtToken ? (
        <>
          <p>Your JWT Token:</p>
          <pre>{jwtToken}</pre> {/* Display the JWT token */}
          <button onClick={logout}>Logout</button> {/* Logout button */}
        </>
      ) : (
        <p>You are not logged in.</p>
      )}
    </div>
  );
};

export default Home;
