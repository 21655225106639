// src/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import awsConfig from "./awsConfig";

AWS.config.update({ region: awsConfig.region });
const cognito = new AWS.CognitoIdentityServiceProvider();

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const loginUser = async () => {
    const params = {
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: awsConfig.clientId,
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
    };

    try {
      const response = await cognito.initiateAuth(params).promise();
      if (response.AuthenticationResult) {
        const { IdToken, AccessToken, RefreshToken } = response.AuthenticationResult;

        // Store the JWT token (IdToken) in localStorage
        localStorage.setItem("idToken", IdToken);
        localStorage.setItem("accessToken", AccessToken); 
        localStorage.setItem("refreshToken", RefreshToken);

        alert("Login successful!");
        navigate("/home"); // Redirect to Home after successful login
      } else {
        alert("Invalid credentials. Please try again.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Login failed. Please try again.");
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={loginUser}>Login</button>
    </div>
  );
};

export default Login;
