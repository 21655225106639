// src/components/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import VerifyOTP from "./VerifyOTP";
import Home from "./Home";
import ViewStudentPhoto from "./viewPhoto";

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/verify-otp" element={<VerifyOTP />} />
      <Route path="/home" element={<Home />} />
      <Route path="/view-student-photo" element={<ViewStudentPhoto />} />
    </Routes>
  </Router>
);

export default App;
